export default [
  {
    header: "Media & Gallery",
  },
  {
    title: "Gallery",
    icon: "CopyIcon",
    children: [
      {
        title: "Media Library",
        route: "gallery-view",
      },
      {
        title: "Add New",
        route: "gallery-add",
      },
    ],
  },
];
