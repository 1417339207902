export default [
  {
    header: "Job Sheets & Leads",
  },
  {
    title: "Job Sheets",
    icon: "ToolIcon",
    route: "jobsheets-view",
  },
  {
    title: "Leads",
    icon: "ActivityIcon",
    route: "leads-view",
  },
];
