export default [
  {
    header: "Brands & Devices",
  },
  {
    title: "Brands",
    icon: "LayersIcon",
    children: [
      {
        title: "All Brands",
        route: "brand-view",
      },
      {
        title: "Add Brand",
        route: "brand-add",
      },
    ],
  },
  {
    title: "Devices",
    icon: "SmartphoneIcon",
    children: [
      // {
      //   title: "All Devices",
      //   route: "device-view",
      // },
      {
        title: "Add Device",
        route: "device-add",
      },
    ],
  },
];
