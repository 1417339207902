export default [
  {
    header: "Issues & Inventory",
  },
  {
    title: "Issues",
    icon: "SlackIcon",
    route: "issues",
  },
  {
    title: "Inventory",
    icon: "CpuIcon",
    route: "inventory-management",
  },
];
