import gallery from "./gallery";
import brandsAndDevices from "./brands-and-devices";
import repairOrders from "./repair-orders";
import issuesAndInventory from "./issues-and-inventory";

export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  ...repairOrders,
  ...gallery,
  ...brandsAndDevices,
  ...issuesAndInventory,
];
